import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './components/Layout';
import Contact from './pages/Contact';
import OurStore from './pages/OurStore';
import Whishlist from './pages/Whishlist';
import Login from './pages/Login';
import Forgotpassword from './pages/Forgotpassword';
import Signup from './pages/Signup';
import Resetpassword from './pages/Resetpassword';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import ShippingPolicy from './pages/ShippingPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import SingleProduct from './pages/SingleProduct';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import PrintOrder from './pages/PrintOrder';
import Orders from './pages/Orders';
import Profile from './pages/Profile';
import Help from './pages/Help';
import SpinnerLoader from './components/SpinnerLoader';
import ScrollToTop from './components/ScrollToTop';
import { jwtDecode } from 'jwt-decode';

// Private Routes - for routes that require authentication
const PrivateRoutes = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" />;
  }
  return children;
};

// Open Routes - for routes that should be accessible only to unauthenticated users
const OpenRoutes = ({ children }) => {
  const token = localStorage.getItem('token');
  if (token) {
    return <Navigate to="/" />;
  }
  return children;
};

const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation(); // Track current location

  // Show spinner on route change
  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulate a loading delay of 1 second

    return () => clearTimeout(timer);
  }, [location]); // Triggered on route change

  // Check token expiry on load
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token); // Decode the token
        const currentTime = Date.now() / 1000; // Get current time in seconds
        if (decoded.exp < currentTime) {
          signOut();
        }
      } catch (error) {
        signOut(); // If decoding fails, sign out the user
      }
    }
  }, []); // Empty dependency to run only on load

  const signOut = () => {
    localStorage.clear();
    window.location.href = '/login'; // Redirect to login
  };

  return (
    <>
      {isLoading && <SpinnerLoader />} {/* Show spinner while loading */}
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='whishlist' element={<PrivateRoutes><Whishlist /></PrivateRoutes>} />
          <Route path='contact' element={<Contact />} />
          <Route path='print' element={<PrintOrder />} />
          <Route path='product' element={<OurStore />} />
          <Route path='category/:category' element={<OurStore />} />
          <Route path='product/:id' element={<SingleProduct />} />
          <Route path='login' element={<OpenRoutes><Login /></OpenRoutes>} />
          <Route path='forgot-password' element={<Forgotpassword />} />
          <Route path='signup' element={<OpenRoutes><Signup /></OpenRoutes>} />
          <Route path='reset-password/:token' element={<Resetpassword />} />
          <Route path='privacy-policy' element={<PrivacyPolicy />} />
          <Route path='refund-policy' element={<RefundPolicy />} />
          <Route path='help' element={<Help />} />
          <Route path='shipping-policy' element={<ShippingPolicy />} />
          <Route path='terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='cart' element={<PrivateRoutes><Cart /></PrivateRoutes>} />
          <Route path='my-orders' element={<PrivateRoutes><Orders /></PrivateRoutes>} />
          <Route path='my-profile' element={<PrivateRoutes><Profile /></PrivateRoutes>} />
          <Route path='checkout' element={<PrivateRoutes><Checkout /></PrivateRoutes>} />
        </Route>
      </Routes>
    </>
  );
};

const RootApp = () => (
  <Router>
    <App />
  </Router>
);

export default RootApp;