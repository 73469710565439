import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { filterProducts } from '../app/features/products/productSlice';

function DropdownItem({ category, setOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleClick = () => {
    if (category && category._id) {
      dispatch(filterProducts({ category: category }));
      navigate(`/product?category=${encodeURIComponent(category._id)}`);
      setOpen(false);
    }
  };

  return (
    <li className='dropdownItem' onClick={handleClick}>
      {category.title}
    </li>
  );
}

export default DropdownItem;