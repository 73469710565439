import axios from "axios";
import { base_url } from "../../../utils/axiosConfig";

const uploadFile = async (data) => {
  try {
    const response = await axios.post(`${base_url}uploadFile/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure the request is multipart
      },
    });
    return response.data; // Returns the uploaded file's data
  } catch (error) {
    console.error('Error uploading file:', error.response || error.message);
    throw error;
  }
};

const deleteFile = async (id) => {
  try {
    const publicId = id.split('/').pop(); 
    const response = await axios.delete(`${base_url}uploadFile/delete/${publicId}?resource_type=raw`);
    console.log(response.data);
    return response.data; // Returns the delete result
  } catch (error) {
    console.error('Error deleting file:', error.response || error.message);
    throw error;
  }
};

const uploadService = {
  uploadFile,
  deleteFile,
};

export default uploadService;