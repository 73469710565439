import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { sizeService } from "./sizeService";

export const getAllSizes = createAsyncThunk('size/get', async (_, thunkAPI) => {
  try {
    return await sizeService.getSizes();
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const sizeState = {
  sizes: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const sizeSlice = createSlice({
  name: 'size',
  initialState: sizeState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(getAllSizes.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getAllSizes.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.sizes = action.payload; 
      
    })
    .addCase(getAllSizes.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.message = action.payload || action.error.message; 
    })
      
  }
});

export default sizeSlice.reducer;
