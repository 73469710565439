import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import uploadService from "./uploadService"; // Ensure the correct import path

// Upload file thunk
export const uploadAFile = createAsyncThunk(
  'upload/files',
  async (files, thunkAPI) => {
    try {
      const formData = new FormData();
      // Append files to the FormData object
      files.forEach(file => formData.append('files', file));

      // Upload file using the service
      const uploadedFiles = await uploadService.uploadFile(formData);
      return uploadedFiles; // Return the uploaded file details (e.g., URL, public_id)
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.message,
        response: error.response?.data || null,
      });
    }
  }
);

// Delete file thunk
export const deleteAFile = createAsyncThunk(
  'delete/file',
  async (id, thunkAPI) => {
    try {
      // Delete file using the service
      await uploadService.deleteFile(id);
      return id; // Return the file ID for the reducer to filter it out
    } catch (error) {
      return thunkAPI.rejectWithValue({
        message: error.message,
        response: error.response?.data || null,
      });
    }
  }
);

// Initial state for the files slice
const initialState = {
  files: [], // Array to hold uploaded files
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "", // For error or success messages
};

// Slice to handle the file upload logic
export const uploadSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle upload actions
      .addCase(uploadAFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadAFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.files = [...state.files, ...action.payload]; // Add new uploaded files to the state
      })
      .addCase(uploadAFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message || 'Upload failed';
      })

      // Handle delete actions
      .addCase(deleteAFile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        // Remove deleted file from the state
        state.files = state.files.filter(file => file.public_id !== action.payload);
      })
      .addCase(deleteAFile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message || 'Delete failed';
      });
  },
});

export default uploadSlice.reducer