import { FaLock } from "react-icons/fa6";
import { FaRegCreditCard } from "react-icons/fa6";
import { BiSupport } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";

export const services = [{
  image: <FaRegCreditCard className="fs-5 home-icon" />,
  title: 'MULTIPLE PAYMENT OPTIONS',
  tagline: 'Choose from various secure payment methods'
},
{
  image: <BiSupport className="fs-5 home-icon" />,
  title: '24/7 CUSTOMER SERVICE',
  tagline: 'Shop with an expert'
},
{
  image: <FaLock className="fs-5 home-icon" />,
  title: 'SECURE PAYMENTS',
  tagline: 'Safe and encrypted transactions'
},

{
  image: <GiReceiveMoney className="fs-5 home-icon" />,
  title: 'MONEY BACK GUARANTEE',
  tagline: '30-day money-back guarantee'
},]