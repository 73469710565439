import axios from "axios";
import { base_url } from "../../../utils/axiosConfig";



export const getSizes = async () => {
  const response = await axios.get(`${base_url}size`);
  return response.data;
};




export const sizeService = {
  getSizes,
  
}