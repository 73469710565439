import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import BlogCard from '../components/BlogCard';
import banner1 from '../images/banner/BANNERPOST-2.jpg';
import banner2 from '../images/banner/BANNERPOST-1.jpg';
import banner3 from '../images/banner/BANNERPOST-3.jpg';
import banner11 from '../images/banner/new banners-3.jpg';
import banne11 from '../images/banner/post kupto-17.jpg';
import banne22 from '../images/banner/post kupto-2.jpg';
import banne33 from '../images/banner/post kupto-11.jpg';
import banne44 from '../images/banner/post kupto-16.jpg';
import banne55 from '../images/banner/post kupto-15.jpg';
import banne66 from '../images/banner/post kupto-6.jpg';
import banne77 from '../images/banner/post kupto-5.jpg';
import banne88 from '../images/banner/post kupto-7.jpg';
import banneaa from '../images/banner/post kupto-3.jpg';
import bannebb from '../images/banner/post kupto-10.jpg';
import bannecc from '../images/banner/post kupto-4.jpg';
import banneDD from '../images/banner/post kupto-14.jpg';
import banneEE from '../images/banner/post kupto-13.jpg';
import banneGG from '../images/banner/post kupto-18.jpg';





import banner22 from '../images/banner/new banners-4.jpg';
import otherbanner1 from '../images/banner/other-banners-2.jpg';
import otherbanner2 from '../images/banner/other-banners-3.jpg';
import otherbanner3 from '../images/banner/other-banners-4.jpg';
import strip from '../images/banner/strip.jpg'
import Container from '../components/Container';
import {services} from '../utils/Data';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import ReactStars from 'react-rating-stars-component';
import { addToWishlists, getAllProducts } from '../app/features/products/productSlice';
import { getAllPosters } from '../app/features/posters/posterSlice';
import { getAllPosts } from '../app/features/Posts/postSlice';
import { MdAdsClick } from "react-icons/md";
import { selectCategories } from '../app/features/category/categorySlice';
import { filterProducts } from '../app/features/products/productSlice';
import Slider from 'react-slick';
import { getAllBrands } from '../app/features/brand/brandSlice';
import { FaRegHeart } from "react-icons/fa";
import { toast } from 'react-toastify';
import 'react-multi-carousel/lib/styles.css';
import { createSelector } from 'reselect';





const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500, 
    slidesToShow: 1, 
    slidesToScroll: 1, 
    autoplay: true, 
    autoplaySpeed: 5000,  
    fade: true,               
    cssEase: 'linear',        
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllProducts());
    dispatch(getAllPosters());
    dispatch(getAllPosts());
    dispatch(getAllBrands());
  }, [dispatch]); 
  
  const productState = useSelector((state) => state?.product?.filteredProducts || []);
  const categories = useSelector(selectCategories);
  const posterState = useSelector((state) => state?.poster?.poster?.posters);
  const postState = useSelector((state)=> state?.post?.post.posts);
  const authState = useSelector(state => state?.auth);
  const navigate = useNavigate();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  

  const responsive3 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
      slidesToSlide: 2
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,

    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
      
    }
  };

  const responsive4 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
      slidesToSlide: 2
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 320 },
      items: 1
    }, 
    smallest: {
      breakpoint: { max: 320, min: 0 },
      items: 1
    }
  };

  const responsive5 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 10,
      slidesToSlide: 2
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 6
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 6
    },
    mobile: {
      breakpoint: { max: 464, min: 320 },
      items: 4
    }, 
    smallest: {
      breakpoint: { max: 320, min: 0 },
      items: 4
    }
  };

  const images = [banne22, banne66, banne77, banne88];


  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const imagess = [banneaa, bannebb, bannecc];


  const [currentImageIndexx, setCurrentImageIndexx] = useState(0);
  
  const addToWish = (id) => {
    if (!authState?.user) {
      // If user is not authenticated, redirect to login page
      toast.info('Please log in to add to wishlist');
      navigate('/login');
    } else {
      // Proceed with adding to wishlist if the user is authenticated
      dispatch(addToWishlists(id))
        .then(() => toast.success('Product added to wishlist!'))
        .catch((error) => toast.error(`Failed to add product to wishlist: ${error.message}`));
    }
  };

  const getStarSize = () => {
    const width = window.innerWidth;
    if (width <= 360) {
      return 12; // Adjust size for small screens (e.g., mobile devices)
    } else if (width <= 768) {
      return 15; 
    } else {
      return 15;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setStarSize(getStarSize());
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [starSize, setStarSize] = React.useState(getStarSize());

  
  const handleClick = (categoryId) => {
    dispatch(filterProducts({ category: categoryId }));
    navigate(`/product?category=${encodeURIComponent(categoryId)}`);
  };

  useEffect(() => {
    // Set an interval to change the image every 5 seconds (5000ms)
    const intervalId = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // 5000ms = 5 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [images.length]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImageIndexx((prevIndex) => (prevIndex + 1) % imagess.length);
    }, 3000); 
    return () => clearInterval(intervalId);
  }, [imagess.length]);


  

  return (
    <>
    <Container class1='home-banner-section'>
          <div className='mm-r'>
          <div className='hero'>
          <Slider {...settings}>
            <div>
              <img src={banner11} alt="Banner 1" className="slider-image" />
            </div>
            <div>
              <img src={banner22} alt="Banner 2" className="slider-image" />
            </div>
            {/* Add more slides as needed */}
          </Slider>
          <div className='not-mob main-banner-content'>
          <h4>GET ALL YOUR</h4>
          <h5>PRINTING</h5>
          <h6><span>&</span> STATIONERY</h6>

          
          <p>on your smartphone</p>
          <div className='home-u d-flex gap-10'>
            <p>with just one</p>
            <p className='clin'>CLICK <MdAdsClick /></p>
            </div>
          <Link to='/print' className='print'>PRINT NOW</Link>
        </div>
          </div>

    

          </div>
    </Container>

    <Container class1='strip-des'>
       <img src={strip} alt='' className='w-100'/>
    </Container>

    

    <Container class1='banner-section-2'>
      <div className='container-fe'>
            <div className='services'>
              {
                services?.map((i,j) => {
                  return(
                    <div className='serv' key={j}>
                      {i.image}
                      <div>
                        <h6>{i.title}</h6>
                        <p>{i.tagline}</p>
                      </div>
                    </div>
                  )
                })
              }
             

            </div>
          
        
      </div>
    </Container>
 
    <Container class1='banner-section-3'>
  <h3 className='section-heading'>Featured Collection</h3>
        <Carousel
        showDots={true}
        itemClass="mb-20"
        className="blog-carousel"
        responsive={responsive}
        infinite={true}  // Enable continuous scrolling
        autoplay={true}  // Enable auto-scrolling
        autoplaySpeed={3000}  // Adjust speed of auto-scrolling
        centerMode={true}  // Center the active item
        swipeable={true}  // Enable swipe functionality for mobile
      >
        {Array.isArray(productState) && productState.length > 0 ? (
          productState
            .filter(item => item.tags && item.tags.includes('featured'))  // Filter for featured products
            .map((item, index) => (
              <div key={item?._id || index} className="col-4">
                <div
                  onClick={() => navigate(`/product/${item?._id}`)}  // Navigate to the single product page
                  className="product-card"
                >
                  <div className="product-image">
                    <img
                      src={item?.images?.[0]?.url || 'default-image-url'}
                      alt={item?.title || 'Product image'}
                      className="prod-img"
                    />
                  </div>

                  {/* Wishlist Icon */}
                  <div 
                    onClick={(e) => {
                      e.stopPropagation();
                      addToWish(item?._id);  // Add to wishlist
                    }} 
                    className="wish">
                    <FaRegHeart className="wish-c" />
                  </div>

                  <div className="product-details">
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <ReactStars
                        count={5}
                        size={starSize}
                        value={Number(item?.totalrating) || 0}
                        edit={false}
                        activeColor="#ffdf00"
                      />
                    </div>
                    <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
                    <p className="price-styleet">
                      UGX <strong>{item?.price || 'N/A'}</strong>
                    </p>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <p>No Featured products found</p>
        )}
      </Carousel>

      </Container>

      

     

    <Container class1='ban'>
    <div className='main-banner-cont'>
          <h4>GET ALL YOUR</h4>
          <h5>PRINTING</h5>
          <h6><span>&</span> STATIONERY</h6>

          
          <p>on your smartphone</p>
          <div className='home-u d-flex gap-10'>
            <p>with just one</p>
            <p className='clin'>CLICK <MdAdsClick /></p>
            </div>
          <Link to='/print' className='printty'>PRINT NOW</Link>
        </div>
    </Container>

    <Container class1='banner-section-3 bord-sub'>
      <div className='sub-flex'>
        <div className='sub-banner'>
          <img src={banner1} alt='' className='w-100' />
          
        </div>
        <div className='sub-banner'>
        <img src={banner2} alt='' className='w-100' />
        
        </div>
        <div className='sub-banner'>
        <img src={banner3} alt='' className='w-100' />
        

        </div>
      </div>
    </Container>

    <Container class1='banner-section-3'>
      <h3 className='section-heading'>Our Categories</h3>
      <Carousel 
      showDots={true} 
      itemClass='mb-20' 
      className='blog mt-2'
      responsive={responsive5}>
      {
              categories?.map((i,j) => {
                  return(
                    <div onClick={() => handleClick(i._id)} className='category' key={j}>
                      <div className='cat-image-d'>
                      <img src={i.images[0]?.url} alt='' className='w-100' />
                      </div>
                      <div >
                        
                        <p>{i.title}</p>
                      </div>
                    </div>
                  )
                })
              }
        
      </Carousel>

      </Container>

      <Container class1='banner-section-3'>
  <h3 className='section-heading'>Featured Collection</h3>
        <Carousel
        showDots={true}
        itemClass="mb-20"
        className="blog-carousel"
        responsive={responsive}
        infinite={true}  // Enable continuous scrolling
        autoplay={true}  // Enable auto-scrolling
        autoplaySpeed={3000}  // Adjust speed of auto-scrolling
        centerMode={true}  // Center the active item
        swipeable={true}  // Enable swipe functionality for mobile
      >
        {Array.isArray(productState) && productState.length > 0 ? (
          productState
            .filter(item => item.tags && item.tags.includes('featured'))  // Filter for featured products
            .map((item, index) => (
              <div key={item?._id || index} className="col-4">
                <div
                  onClick={() => navigate(`/product/${item?._id}`)}  // Navigate to the single product page
                  className="product-card"
                >
                  <div className="product-image">
                    <img
                      src={item?.images?.[0]?.url || 'default-image-url'}
                      alt={item?.title || 'Product image'}
                      className="prod-img"
                    />
                  </div>

                  {/* Wishlist Icon */}
                  <div 
                    onClick={(e) => {
                      e.stopPropagation();
                      addToWish(item?._id);  // Add to wishlist
                    }} 
                    className="wish">
                    <FaRegHeart className="wish-c" />
                  </div>

                  <div className="product-details">
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <ReactStars
                        count={5}
                        size={starSize}
                        value={Number(item?.totalrating) || 0}
                        edit={false}
                        activeColor="#ffdf00"
                      />
                    </div>
                    <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
                    <p className="price-styleet">
                      UGX <strong>{item?.price || 'N/A'}</strong>
                    </p>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <p>No Featured products found</p>
        )}
      </Carousel>

      </Container>
      

      <Container class1='banner-section-3 bord-sub'>
      <div className='sub-flex'>
        <div className='sub-banner'>
          <img src={banneDD} alt='' className='w-100 mb-5' />
          <img src={banneEE} alt='' className='w-100' />
          
        </div>
        <div className="sub-banner">
      <img src={images[currentImageIndex]} alt="" className="w-100" />
    </div>
        <div className='sub-banner position-relative'>
        <img src={banne33} alt='' className='w-100 mb-5' />
        <img src={banneGG} alt='' className='w-100' />
        <div className='print-sty'>
          <h2>DIGITAL PRINT</h2>
          <p>Business cards / Flyers / Brochures /
              Magazines / Posters /Envelopes / Calendars /
              Folders / Menus / Postcards / Invitation cards /
              Packaging & Design Etc.</p>
        </div>

        </div>
      </div>
    </Container>

    <Container class1='other-bann d-flex flex-column '>
        <div className='top-banners d-flex gap-10'>
          <div>
            <img src={otherbanner1} alt='' className='w-100' />
          </div>
          <div>
            <img src={otherbanner2} alt='' className='w-100' />
          </div>
        </div>

        <div className='lower-main mt-8'>
          <img src={otherbanner3} alt='' className='w-100' />
        </div>
      </Container>

  

      <Container class1="special-wrapper">
  <h3 className="section-heading">Newest Products</h3>

  <Carousel
  showDots={true}
  itemClass="mb-20"
  className="blog-carousel"
  responsive={responsive}
  infinite={true}  // Enable continuous scrolling
  autoplay={true}  // Enable auto-scrolling
  autoplaySpeed={3000}  // Adjust speed of auto-scrolling
  centerMode={true}  // Center the active item
  swipeable={true}  // Enable swipe functionality for mobile
>
  {Array.isArray(productState) && productState.length > 0 ? (
    productState
      .filter(item => item.tags && item.tags.includes('featured'))  // Filter for featured products
      .map((item, index) => (
        <div key={item?._id || index} className="col-4">
          <div
            onClick={() => navigate(`/product/${item?._id}`)}  // Navigate to the single product page
            className="product-card"
          >
            <div className="product-image">
              <img
                src={item?.images?.[0]?.url || 'default-image-url'}
                alt={item?.title || 'Product image'}
                className="prod-img"
              />
            </div>

            {/* Wishlist Icon */}
            <div 
              onClick={(e) => {
                e.stopPropagation();
                addToWish(item?._id);  // Add to wishlist
              }} 
              className="wish">
              <FaRegHeart className="wish-c" />
            </div>

            <div className="product-details">
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <ReactStars
                  count={5}
                  size={starSize}
                  value={Number(item?.totalrating) || 0}
                  edit={false}
                  activeColor="#ffdf00"
                />
              </div>
              <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
              <p className="price-styleet">
                UGX <strong>{item?.price || 'N/A'}</strong>
              </p>
            </div>
          </div>
        </div>
      ))
  ) : (
    <p>No Featured products found</p>  // Message when no featured products exist
  )}
</Carousel>
</Container>

    

    <Container class1='descrip-section'>
      <div className='des-cont'>

      {postState && postState.length > 0 ? (
          postState.map((item, index) => (
                <div className='desss' key={index}>
                  <div className='des-doc'>
                    <div className='position-absolute'>
                    
                    
                    </div>
                    <img src={item?.images[0]?.url} alt='' className='descrip-photo' />
                  </div>
                </div>
          ))
        ) : (
          <p>No posts found</p>
        )}
   
        </div>
    </Container>

    

    <Container class1='banner-section-3 our-none'>
  <h3 className='section-heading'>Our Popular Products</h3>

  <Carousel
  showDots={true}
  itemClass="mb-20"
  className="blog-carousel"
  responsive={responsive}
  infinite={true}  // Enable continuous scrolling
  autoplay={true}  // Enable auto-scrolling
  autoplaySpeed={3000}  // Adjust speed of auto-scrolling
  centerMode={true}  // Center the active item
  swipeable={true}  // Enable swipe functionality for mobile
>
  {Array.isArray(productState) && productState.length > 0 ? (
    productState
      .filter(item => item.tags && item.tags.includes('featured'))  // Filter for featured products
      .map((item, index) => (
        <div key={item?._id || index} className="col-4">
          <div
            onClick={() => navigate(`/product/${item?._id}`)}  // Navigate to the single product page
            className="product-card"
          >
            <div className="product-image">
              <img
                src={item?.images?.[0]?.url || 'default-image-url'}
                alt={item?.title || 'Product image'}
                className="prod-img"
              />
            </div>

            {/* Wishlist Icon */}
            <div 
              onClick={(e) => {
                e.stopPropagation();
                addToWish(item?._id);  // Add to wishlist
              }} 
              className="wish">
              <FaRegHeart className="wish-c" />
            </div>

            <div className="product-details">
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <ReactStars
                  count={5}
                  size={starSize}
                  value={Number(item?.totalrating) || 0}
                  edit={false}
                  activeColor="#ffdf00"
                />
              </div>
              <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
              <p className="price-styleet">
                UGX <strong>{item?.price || 'N/A'}</strong>
              </p>
            </div>
          </div>
        </div>
      ))
  ) : (
    <p>No Featured products found</p>  // Message when no featured products exist
  )}
</Carousel>
</Container>

<Container class1='blog-wrapper dis-post'>
  <h3 className='section-heading'>Our Latest Posts</h3>
  <Carousel
      showDots={false}
      itemClass='mb-20'
      className='blog'
      responsive={responsive3}
      autoPlay={true} // Enable autoPlay globally
      autoPlaySpeed={6000} // Set the speed for auto-play
      infinite={true} // Ensure the carousel loops infinitely
      centerMode={true} // Enable centered mode for active items
      swipeable={true} // Enable swiping functionality on mobile
      pauseOnHover={false} // Ensure autoplay continues when hovering
    > 
    {Array.isArray(posterState) && posterState.length > 0 ? (
      posterState.map((item) => (
        <BlogCard
          key={item?._id || item?.title || item?.date}
          id={item?._id}
          description={item?.description || 'No description available'}
          title={item?.title || 'Untitled'}
          images={item?.images}
          date={item?.date || 'No date available'}
        />
      ))
    ) : (
      <p>No posts available.</p>
    )}
  </Carousel>
</Container>

<Container class1='banner-section-3 our-pop'>
  <h3 className='section-heading'>Our Popular Products</h3>
  <Carousel
  showDots={true}
  itemClass="mb-20"
  className="blog-carousel"
  responsive={responsive}
  infinite={true}  // Enable continuous scrolling
  autoplay={true}  // Enable auto-scrolling
  autoplaySpeed={3000}  // Adjust speed of auto-scrolling
  centerMode={true}  // Center the active item
  swipeable={true}  // Enable swipe functionality for mobile
>
  {Array.isArray(productState) && productState.length > 0 ? (
    productState
      .filter(item => item.tags && item.tags.includes('featured'))  // Filter for featured products
      .map((item, index) => (
        <div key={item?._id || index} className="col-4">
          <div
            onClick={() => navigate(`/product/${item?._id}`)}  // Navigate to the single product page
            className="product-card"
          >
            <div className="product-image">
              <img
                src={item?.images?.[0]?.url || 'default-image-url'}
                alt={item?.title || 'Product image'}
                className="prod-img"
              />
            </div>

            {/* Wishlist Icon */}
            <div 
              onClick={(e) => {
                e.stopPropagation();
                addToWish(item?._id);  // Add to wishlist
              }} 
              className="wish">
              <FaRegHeart className="wish-c" />
            </div>

            <div className="product-details">
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <ReactStars
                  count={5}
                  size={starSize}
                  value={Number(item?.totalrating) || 0}
                  edit={false}
                  activeColor="#ffdf00"
                />
              </div>
              <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
              <p className="price-styleet">
                UGX <strong>{item?.price || 'N/A'}</strong>
              </p>
            </div>
          </div>
        </div>
      ))
  ) : (
    <p>No Featured products found</p>  // Message when no featured products exist
  )}
</Carousel>
  
</Container>


<Container class1='descrip-section dis-no'>

<Carousel
      showDots={false}
      itemClass='mb-20' // Custom styling for each carousel item
      className='des-cont' // Custom class for styling the carousel
      responsive={responsive4} // Ensure responsive breakpoints are defined
      autoPlay={true} // Enable autoplay
      autoPlaySpeed={6000} // Speed of autoplay (in milliseconds)
      infinite={true} // Infinite loop for the carousel
      centerMode={true} // Center the active item
      swipeable={true} // Allow swiping to move items
      pauseOnHover={false} // Disable pause on hover
    >
  {postState && postState.length > 0 ? (
    postState.map((item) => (
      <div className='desss' key={item?._id || item?.title || `fallback-${item?.index}`}>
        <div className='des-doc'>
          <div className='position-absolute new-desss'>
            {/* Additional content can go here if needed */}
          </div>
          <img 
            src={item?.images?.[0]?.url || 'default-image-url'} // Fallback to a placeholder image
            alt={item?.title || 'Description Image'} // Use meaningful alt text
            className='descrip-photo' 
          />
        </div>
      </div>
    ))
  ) : (
    <p>No posts found</p> // Display a message if there are no posts
  )}
</Carousel>
</Container>

<Container class1='blog-wrapper big-post'>
  <h3 className='section-heading'>Our Latest Posts</h3>
  <Carousel
  showDots={false}
  itemClass="mb-21"
  className="custom-blog-carousel"
  responsive={responsive3}
  infinite={true}  // Enable continuous scrolling
  autoPlay={true}  // Enable auto-scrolling
  autoPlaySpeed={6000}  // Adjust speed of auto-scrolling
  centerMode={true}  // Center the active item
  swipeable={true}  // Allow swiping (but ensure autoplay works on mobile)
  transitionDuration={500}  // Set transition duration for smooth animation
  pauseOnHover={false}  // Disable pause on hover, autoplay will continue even if hovered
>
  {Array.isArray(posterState) && posterState.length > 0 ? (
    posterState.map((item) => (
      <BlogCard
        key={item?._id || item?.title || item?.date}  // Ensure a unique key if _id is unavailable
        id={item?._id}
        description={item?.description || 'No description available'}
        title={item?.title || 'Untitled'}
        images={item?.images}  // Ensure the BlogCard can handle the images array
        date={item?.date || 'No date available'}
      />
    ))
  ) : (
    <p>No posts available.</p>
  )}
</Carousel>
</Container>

<Container class1='banner-section-3'>
  <h3 className='section-heading'>Product Printing</h3>
  <Carousel
  showDots={true}
  itemClass="mb-20"
  className="blog-carousel"
  responsive={responsive}
  infinite={true}
  autoplay={true}
  autoplaySpeed={3000}
  centerMode={true} 
  swipeable={true} 
>
  {Array.isArray(productState) && productState.length > 0 ? (
    productState
      .filter(item => item.tags && item.tags.includes('featured'))
      .map((item, index) => (
        <div key={item?._id || index} className="col-4">
          <div
            onClick={() => navigate(`/product/${item?._id}`)}
            className="product-card"
          >
            <div className="product-image">
              <img
                src={item?.images?.[0]?.url || 'default-image-url'}
                alt={item?.title || 'Product image'}
                className="prod-img"
              />
            </div>

            {/* Wishlist Icon */}
            <div 
              onClick={(e) => {
                e.stopPropagation();
                addToWish(item?._id);  // Add to wishlist
              }} 
              className="wish">
              <FaRegHeart className="wish-c" />
            </div>

            <div className="product-details">
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <ReactStars
                  count={5}
                  size={starSize}
                  value={Number(item?.totalrating) || 0}
                  edit={false}
                  activeColor="#ffdf00"
                />
              </div>
              <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
              <p className="price-styleet">
                UGX <strong>{item?.price || 'N/A'}</strong>
              </p>
            </div>
          </div>
        </div>
      ))
  ) : (
    <p>No Featured products found</p>  // Message when no featured products exist
  )}
</Carousel>
</Container>

<Container class1='banner-section-3 bord-sub'>
      <div className='sub-flex'>
        <div className='sub-banner'>
          <img src={banne11} alt='' className='w-100 mb-5' />
          <img src={banne44} alt='' className='w-100' />
          
        </div>
        <div className="sub-banner">
      <img src={imagess[currentImageIndexx]} alt="" className="w-100" />
    </div>
        <div className='sub-banner position-relative'>
        <img src={banne33} alt='' className='w-100 mb-5' />
        <img src={banne55} alt='' className='w-100' />
        <div className='print-sty'>
          <h2>BRANDED ITEMS</h2>
          <p>Branded Cups / Diaries / Cd Branding / Travel Mugs /  MousePads / Gift Bags / Key Holders / Water Bottles /  Umbrellas / Paper Bags / Branded Helmets / Pens etc.</p>
        </div>

        </div>
      </div>
    </Container>

    <Container class1='banner-section-3 mbbb'>
  <h3 className='section-heading'>Printing</h3>
        <Carousel
        showDots={true}
        itemClass="mb-20"
        className="blog-carousel"
        responsive={responsive}
        infinite={true}  // Enable continuous scrolling
        autoplay={true}  // Enable auto-scrolling
        autoplaySpeed={7000}  // Adjust speed of auto-scrolling
        centerMode={true}  // Center the active item
        swipeable={true}  // Enable swipe functionality for mobile
      >
        {Array.isArray(productState) && productState.length > 0 ? (
          productState
            .filter(item => item.tags && item.tags.includes('featured'))  // Filter for featured products
            .map((item, index) => (
              <div key={item?._id || index} className="col-4">
                <div
                  onClick={() => navigate(`/product/${item?._id}`)}  // Navigate to the single product page
                  className="product-card"
                >
                  <div className="product-image">
                    <img
                      src={item?.images?.[0]?.url || 'default-image-url'}
                      alt={item?.title || 'Product image'}
                      className="prod-img"
                    />
                  </div>

                  {/* Wishlist Icon */}
                  <div 
                    onClick={(e) => {
                      e.stopPropagation();
                      addToWish(item?._id);  // Add to wishlist
                    }} 
                    className="wish">
                    <FaRegHeart className="wish-c" />
                  </div>

                  <div className="product-details">
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                      <ReactStars
                        count={5}
                        size={starSize}
                        value={Number(item?.totalrating) || 0}
                        edit={false}
                        activeColor="#ffdf00"
                      />
                    </div>
                    <p className="brand mb-5">{item?.title || 'Untitled Product'}</p>
                    <p className="price-styleet">
                      UGX <strong>{item?.price || 'N/A'}</strong>
                    </p>
                  </div>
                </div>
              </div>
            ))
        ) : (
          <p>No Featured products found</p>
        )}
      </Carousel>

      </Container>



   



    </>
  )
}

export default Home;


