import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import logo from '../images/logo/KUPTO LOGO-2.png';
import Container from '../components/Container';
import { deleteUserCart, getUserCart, resetState, createAnOrder } from '../app/features/user/userSlice';
import Meta from '../components/Meta';
import BreadCrumb from '../components/BreadCrumb';

// Yup validation schema
const shippingSchema = yup.object({
  firstName: yup.string().required('First Name is Required'),
  lastName: yup.string().required('Last Name is Required'),
  address: yup.string().required('Address Details are Required'),
  region: yup.string().required('Region is Required'),
  subRegion: yup.string().required('Sub Region is Required'),
  paymentMethod: yup.string().required('Payment Method is Required'),
});

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cartState = useSelector(state => state?.auth?.cartProduct);
  const authState = useSelector(state => state?.auth);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [paypalReady, setPaypalReady] = useState(false);

  

  // Formik initialization
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      address: '',
      region: '',
      subRegion: '',
      paymentMethod: '',
    },
    validationSchema: shippingSchema,
    onSubmit: async (values) => {
      if (!cartState || cartState.length === 0) {
        console.error("Cart is empty. Cannot submit order.");
        return; // Prevent further execution
      }
      
      const orderItems = cartState.map(item => {
        if (!item.productId || !item.color) {
          // Skip invalid items
          console.log("Skipping item due to missing data:", item);
          return null;
        }
  
        return {
          product: item.productId._id,
          quantity: item.quantity,
          size: item.size ? item.size._id : null,  // Ensure size exists
          color: item.color._id,
          price: item.price,
          instruction: item.instruction,
          uploadedFiles: item.uploadedFiles,
        };
      }).filter(item => item !== null); // Filter out invalid items
  
      console.log("Processed Order Items:", orderItems);
  
      // Check if orderItems is empty
      if (orderItems.length === 0) {
        console.error("No valid items to submit in the order.");
        return; // Prevent order submission if no valid items
      }
  
      await dispatch(createAnOrder({
        totalPrice: totalAmount,
        totalPriceAfterDiscount: totalAmount,
        orderItems: orderItems,
        paymentInfo: { paymentMethod: values.paymentMethod },
        shippingInfo: {
          firstName: values.firstName,
          lastName: values.lastName,
          address: values.address,
          region: values.region,
          subRegion: values.subRegion,
        },
      })).unwrap();
  
      // Clear cart and reset state after order submission
      dispatch(deleteUserCart());
      dispatch(resetState());
      navigate('/my-orders');
    }
  });
  
  // PayPal success handler
  const handlePaypalSuccess = useCallback((details) => {
    // Ensure the PayPal payment details exist before proceeding
    if (!details || !details.payer || !details.paymentID) {
      console.error("PayPal payment details are missing or incomplete.");
      return;
    }
  
    dispatch(createAnOrder({
      totalPrice: totalAmount,
      totalPriceAfterDiscount: totalAmount,
      orderItems: cartState?.map(item => {
        if (!item.productId || !item.color) {
          return null; // Skip invalid items
        }
  
        return {
          product: item.productId._id,
          quantity: item.quantity,
          size: item.size ? item.size._id : null,  // Handle size safely
          color: item.color._id,
          price: item.price,
        };
      }).filter(item => item !== null), // Filter out invalid items
      paymentInfo: { method: 'PayPal', details },
      shippingInfo: {
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        address: formik.values.address,
        region: formik.values.region,
        subRegion: formik.values.subRegion,
      },
    }));
  
    // Reset the cart and navigate after order creation
    dispatch(deleteUserCart());
    dispatch(resetState());
    navigate('/my-orders');
  }, [dispatch, totalAmount, cartState, formik.values, navigate]);

  useEffect(() => {
    dispatch(getUserCart());
  }, [dispatch]);

  useEffect(() => {
    let sum = 0;
    cartState?.forEach(item => {
      sum += item.quantity * item.price;
    });
    setTotalAmount(sum);
  }, [cartState]);

  useEffect(() => {
    if (authState?.orderedProduct?.order && authState?.orderedProduct?.success) {
      navigate('/my-orders');
    }
  }, [authState, navigate]);

  useEffect(() => {
    const loadPaypalScript = async () => {
      const res = await loadScript('https://www.paypal.com/sdk/js?client-id=AUfaliDwiTlauFiq2TzfVU0AZtjwTad9iYScffpyIbhxz2jLyU3wcfyo8b8Q2PUzlL4GaftsEXSQV2kz');
      
      if (!res) {
        alert('Failed to load PayPal script.');
      } else {
        setPaypalReady(true);
      }
    };
    if (formik.values.paymentMethod === 'PayPal') {
      loadPaypalScript();
    }
  }, [formik.values.paymentMethod]);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const handleRegionChange = (e) => {
    const region = e.target.value;
    setSelectedRegion(region);
    formik.setFieldValue('region', region);
    formik.setFieldValue('subRegion', '');
  };

  const getSubRegions = (region) => {
    const subRegions = {
      'Kampala Region': ['Buziga', 'Banda', 'Bakuli', 'Kibuye', 'Katwe', 'Buwate', 'Nakasero', 'Kololo', 'Bwaise','Kanyanya', 'Makerere', 'Luwafu', 'Nsambya', 'Naguru', 'Bugolobi', 'Ntinda', 'Rubaga', 'Nateete', 'Namirembe'],
      'Eastern Region': ['Jinja', 'Mbale', 'Soroti'],
      'Entebbe Area': ['Entebbe City', 'Kajjansi'],
      'Western Region': ['Mbarara', 'Kabale', 'Fort Portal'],
      'Northern Region': ['Gulu', 'Lira', 'Kitgum'],
      'Rest Of Central Region': ['Masaka', 'Mukono', 'Luwero'],
    };
    return subRegions[region] || [];
  };

  const subRegions = getSubRegions(selectedRegion);

  useEffect(() => {
    if (paypalReady && formik.values.paymentMethod === 'PayPal') {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: totalAmount.toFixed(2),
              },
            }],
          });
        },
        onApprove: async (data, actions) => {
          const details = await actions.order.capture();
          handlePaypalSuccess(details);
        },
        onError: (err) => {
          console.error('PayPal error:', err);
          alert('An error occurred with PayPal payment.');
        },
      }).render('#paypal-button-container');
    }
  }, [paypalReady, formik.values.paymentMethod, handlePaypalSuccess, totalAmount]);

  return (
    <>
      <Meta title="Checkout" />
      <BreadCrumb title="Checkout" />
      <Container class1='checkout-wrapper'>
        <div className='d-flex check-dis'>
        <div className='checkout-right-data check-none'>
          <div className='checkout-left-data'>
          
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb d-flex gap-10" style={{ "--bs-breadcrumb-divider": ">" }}>
                <li className="breadcrumb-item">
                  <Link to='/cart' className='dark-font'>Cart</Link>
                </li>
                <li className="breadcrumb-item active dark-font" aria-current="page">Information</li>
                <li className="breadcrumb-item">
                  <Link to='/cart' className='active'>Shipping</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Payment</li>
              </ol>
            </nav>
          </div>
            <div className='border-bottom marg-check'>
              {cartState && cartState.map((item, index) => (
                <div key={index} className='d-flex align-items-center justify-content-between mb-10'>
                  <div className='check-image'>
                    <span className='span-cart'>{item.quantity}</span>
                    <img src={item?.productId?.images[0]?.url} alt='' className='w-100' />
                  </div>
                  <div className='check-details'>
                    <p className='para-14'>{item.productId.title}</p>
                    <p className='para-14'>{item?.color?.title}</p>
                    <p className='para-14'>{item?.size?.title}</p>
                  </div>
                  <div className='check-price'>
                    <p className='price-style'>UGX <strong>{item.price * item.quantity}</strong></p>
                  </div>
                </div>
              ))}
            </div>
            <div className='border-bottom'>
              <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
                <h3 className='grey-font'>SubTotal</h3>
                <p className='price-fin'>UGX <strong>{totalAmount || '0'}</strong></p>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
                <h3 className='grey-font'>Shipping</h3>
                <h3 className='font-sub'>UGX 2,000</h3>
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
              <h3>Total</h3>
              <p className='price-fin'>UGX <strong>{totalAmount + 2000 || '0'}</strong></p>
            </div>
          </div>

          <div className='checkout-left-data f-chech'>
            
            <nav className='none-chech' aria-label="breadcrumb">
              <ol className="breadcrumb d-flex gap-10" style={{ "--bs-breadcrumb-divider": ">" }}>
                <li className="breadcrumb-item">
                  <Link to='/cart' className='dark-font'>Cart</Link>
                </li>
                <li className="breadcrumb-item active dark-font" aria-current="page">Information</li>
                <li className="breadcrumb-item">
                  <Link to='/cart' className='active'>Shipping</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">Payment</li>
              </ol>
            </nav>
            
            <h3 className='font-tt'>Choose your Location</h3>
            <form onSubmit={formik.handleSubmit}>
              <div className='loc-inp d-flex gap-10 align-items-center mt-10 mb-10'>
                <div className='form-inputt'>
                  <select
                    className='new-s'
                    name='region'
                    value={formik.values.region}
                    onChange={handleRegionChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="" label="Select region" />
                    <option value="Kampala Region">Kampala Region</option>
                    <option value="Eastern Region">Eastern Region</option>
                    <option value="Entebbe Area">Entebbe Area</option>
                    <option value="Western Region">Western Region</option>
                    <option value="Northern Region">Northern Region</option>
                    <option value="Rest Of Central Region">Rest Of Central Region</option>
                  </select>
                  {formik.touched.region && formik.errors.region ? (
                    <div className="error">{formik.errors.region}</div>
                  ) : null}
                </div>
                <div className='form-inputt'>
                  <select
                    className='new-s'
                    name='subRegion'
                    value={formik.values.subRegion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!selectedRegion}
                  >
                    <option value="" label="Select sub-region" />
                    {subRegions.map((subRegion, index) => (
                      <option key={index} value={subRegion}>
                        {subRegion}
                      </option>
                    ))}
                  </select>
                  {formik.touched.subRegion && formik.errors.subRegion ? (
                    <div className="error">{formik.errors.subRegion}</div>
                  ) : null}
                </div>
              </div>
              <div className='loc-inp d-flex gap-10 mt-10'>
                <div className='form-inputt2'>
                  <input
                    type='text'
                    name='firstName'
                    className='new-s'
                    placeholder='First Name'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="error">{formik.errors.firstName}</div>
                  ) : null}
                </div>
                <div className='form-inputt2'>
                  <input
                    type='text'
                    name='lastName'
                    className='new-s'
                    placeholder='Last Name'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="error">{formik.errors.lastName}</div>
                  ) : null}
                </div>
              </div>
              <div className='form-inputt mt-10'>
                <input
                  type='text'
                  name='address'
                  className='new-s'
                  placeholder='Address'
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.address && formik.errors.address ? (
                  <div className="error">{formik.errors.address}</div>
                ) : null}
              </div>
              <h4 className='font-tt ' style={{ marginTop: '20px', marginBottom: '10px' }}>Payment Method</h4>
              <div className='loc-inp d-flex gap-10 align-items-center mb-10'>
              
                <div className='form-inputt2'>
                <input
                  type='radio'
                  name='paymentMethod'
                  value='Mobile Money'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  checked={formik.values.paymentMethod === 'Mobile Money'}
                />
                  Mobile Money
                </div>
                <div className='form-inputt2'>
                  <input
                    type='radio'
                    name='paymentMethod'
                    value='PayPal'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.paymentMethod === 'PayPal'}
                  />
                  PayPal
                </div>
                <div className='form-inputt2'>
                  <input
                    type='radio'
                    name='paymentMethod'
                    value='Cash on Delivery'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.paymentMethod === 'Cash on Delivery'}
                  />
                  Cash on Delivery
                </div>
              </div>
              
              {formik.touched.paymentMethod && formik.errors.paymentMethod ? (
                <div className="error">{formik.errors.paymentMethod}</div>
              ) : null}
              
              

              {formik.values.paymentMethod === 'PayPal' && paypalReady ? (
                  <div id="paypal-button-container"></div>
                ) : (
                  <button type='submit' className='button mbbb'>Complete Order</button>
                )}
              
             
            </form>
          </div>
          
          <div className='checkout-right-data check-mob'>
            <div className='border-bottom'>
              {cartState && cartState.map((item, index) => (
                <div key={index} className='d-flex align-items-center justify-content-between mb-10'>
                  <div className='check-image'>
                    <span className='span-cart'>{item.quantity}</span>
                    <img src={item?.productId?.images[0]?.url} alt='' className='w-100' />
                  </div>
                  <div className='check-details'>
                    <p className='para-14'>{item?.productId?.title}</p>
                    <p className='para-14'>{item?.color?.title}</p>
                    <p className='para-14'>{item?.size?.title}</p>
                  </div>
                  <div className='check-price'>
                    <p className='price-style'>UGX <strong>{item.price * item.quantity}</strong></p>
                  </div>
                </div>
              ))}
            </div>
            <div className='border-bottom'>
              <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
                <h3 className='grey-font'>SubTotal</h3>
                <p className='price-fin'>UGX <strong>{totalAmount || '0'}</strong></p>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
                <h3 className='grey-font'>Shipping</h3>
                <h3 className='font-sub'>UGX 2,000</h3>
              </div>
            </div>
            <div className='d-flex align-items-center justify-content-between mb-10 mt-20'>
              <h3>Total</h3>
              <p className='price-fin'>UGX <strong>{totalAmount + 2000 || '0'}</strong></p>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Checkout;




